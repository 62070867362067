import AXIOS from "services/axios";
export const getGeneratedReportList = async (
  id,
  sgId,
  reportType,
  startDate,
  endDate,
  type
) => {
  const response = { data: null, error: null };
  try {
    if (reportType === "dashboard") {
      let url = `${process.env.REACT_APP_API_PATH}/reportdetails/smar_report/${id}`;

      const params = new URLSearchParams();

      if (sgId !== "") {
        params.append("sgId", sgId);
      }

      if (startDate) {
        params.append("start_date", startDate);
      }

      if (endDate) {
        params.append("end_date", endDate);
      }

      if (type !== "" && type !== undefined) {
        params.append("type", type);
      }

      const queryString = params.toString();
      if (queryString) {
        url += `?${queryString}`;
      }

      const { data } = await AXIOS.get(url);
      response.data = data;
    } else {
      const { data } = await AXIOS.get(
        `${process.env.REACT_APP_API_PATH}/reportdetails/smar_report/`
      );
      response.data = data;
    }
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const postGenerateReport = async (reqObj) => {
  const response = { data: null, error: null };

  try {
    const { data } = await AXIOS.post(
      `${process.env.REACT_APP_API_PATH}/reportdetails/smar_report/`,
      reqObj
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};
export const getTotalPostData = async (id, sgId) => {
  const response = { data: null, error: null };
  try {
    let url = `${process.env.REACT_APP_API_PATH}/reportdetails/total_posts_data/${id}/`;

    if (sgId) {
      url += `?sgId=${sgId}`;
    }

    const { data } = await AXIOS.get(url);
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};
