import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useContext,
} from "react";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

//mui
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { CircularProgress, FormControl } from "@mui/material";
import { Box, Chip } from "@mui/material";

//components
import CustomButton from "components/Common/CustomButton";
import PdfImageCard from "components/Card/PdfImageCard";
import GeneratePdf from "components/Pdf/GeneratePdf";
import SimpleTable from "components/Tables/SimpleTable";
import SimpleTableTwitter from "components/Tables/SimpleTableTwitter";
import { ReactComponent as SelectedTabularIcon } from "assets/SquareHalfBottom.svg";
import { ReactComponent as GraphicalIcon } from "assets/ChartLineUp.svg";
import { ReactComponent as TabularIcon } from "assets/SquareHalfBottomBlack.svg";
import { ReactComponent as SelectedGraphicalIcon } from "assets/ChartLineUpBlue.svg";
import TopPosts from "components/Sections/TotalPosts";
import LineChartComponent from "components/Charts/LineChart";
import ToggleGroup from "components/ToggleGroup/toggleGroup";
import Loader from "components/Common/Loader";
import GraphSection from "pages/app/smar-dashboard/components/graphs";
import SimpleTableYoutube from "components/Tables/SimpleTableYoutube";

//apis
import { getCompleteReport } from "apis/componentsAction/reports";

//context
import { AuthContext } from "contexts/AuthContext";
import { AlertContext } from "contexts/AlertContext";

//api
import {
  getGeneratedReportList,
  getTotalPostData,
} from "apis/componentsAction/smar-dashboard";
import PdfSmarDashboard from "components/Pdf/PdfSmarDashboard";
import {
  columnHeaderFacebookDaily,
  columnHeaderFacebookMonthly,
  columnHeaderFacebookWeekly,
  columnHeaderYoutubeDaily,
  columnHeaderYoutubeMonthly,
  columnHeaderYoutubeWeekly,
} from "utils/data";
import ExportDropdown from "components/Common/ExportDropdown";

const Container = styled("div")(({ theme }) => ({
  "& .button_container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0.5rem 1.5rem",
    "& .button_style": {
      borderRadius: "20px",
    },
  },
  "& .report_container": {
    // display: "none",
    padding: "0 1.5rem 1rem 1.5rem",
    maxHeight: "72vh",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      height: "0.3em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#cccccc",
    },
  },
  "& .group_heading_container": {
    margin: "1.5rem 0",
    display: "flex",
    justifyContent: "space-between",
    height: "24px",
    maxHeight: "24px",
    "& .heading": {
      color: "#4F4F4F",
      fontWeight: "700",
      fontSize: "20px",
      [theme.breakpoints.down("xl")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "16px",
      },
    },
  },
  "& .body_row": {
    height: "72px",
    maxHeight: "72px",
  },
  "& .img_card_container": {
    padding: "2rem 0 0 0",
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",
    [theme.breakpoints.down("xl")]: {
      gap: "0.8rem",
    },
    [theme.breakpoints.down("lg")]: {
      gap: "0.6rem",
    },
  },
  "& .bar_graph": {
    marginTop: "4rem",
    width: "100%",
    // border: "1px solid red",
    // maxWidth: "97%",
    // overflowX: "auto",
    "& .filters": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      "& .MuiButtonBase-root": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
  "& .data_container": {
    padding: "1.5rem",
    borderRadius: "0.875rem",
    border: "1px solid #E3F1FF",
    margin: "1.5rem 0",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
  },
  "& .chip_data_container": {
    padding: "1.5rem 1.5rem 1rem 1.5rem",
    borderRadius: "0.875rem",
    border: "1px solid #E3F1FF",
    // margin: "1.5rem 0",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
  },
}));
const StyledIconButton = styled(IconButton)(({ theme, disabled }) => ({
  marginRight: "8px",
  padding: "5px",
  "& .editIcon": {
    fontSize: "1.4rem",
    // color: "#4F4F4F",
    color: disabled ? "#e6e6e6" : "#FF5252",
    background: "#EFFFFD",
    [theme.breakpoints.down("xl")]: {
      fontSize: "1.2rem",
    },
  },
  "& .delIcon": {
    fontSize: "2rem",
    // color: "#FF5252", #e6e6e6
    background: "#EFFFFD",
    color: disabled ? "#e6e6e6" : "#FF5252",
    [theme.breakpoints.down("xl")]: {
      fontSize: "1.5rem",
    },
  },
}));
const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};
const options_range = { year: "numeric", month: "long", day: "numeric" };
const graphKeys = ["total_likes", "total_posts", "total_shares"];
const medianGraphKeys = ["total_likes", "median_shares", "total_shares"];
const ShowReportsPreview = ({ modalData, handleClose }) => {
  const [medianGraphData, setMedianGraphData] = useState([]);
  const [totalGraphData, setTotalGraphData] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentMedianPage, setCurrentMedianPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [medianPageCount, setMedianPageCount] = useState(0);
  const [completeDashboardData, setCompleteDashboardData] = useState([]);
  const [totalData, setTotalData] = useState({});
  const [mediaTotalData, setMedianTotalData] = useState({});
  const [topPostData, setTopPostData] = useState({});
  const [totalPostData, setTotalPostData] = useState([]);
  const [selectedToggles, setSelectedToggles] = useState({});
  const [loading, setIsLoading] = useState(false);
  const [csvFile, setCsvFile] = useState();

  const limit = 6;
  const myRef = useRef();
  const { user } = useContext(AuthContext);
  const { showAlert } = useContext(AlertContext);
  const handleChange = (selectedValue, smarGroupId) => {
    setSelectedToggles((prevToggles) => ({
      ...prevToggles,
      [smarGroupId]: selectedValue,
    }));
  };
  const hasTotalPostData = (data) => {
    return (
      (Array.isArray(data) && data.length > 0) ||
      (data &&
        Array.isArray(data.Total_posts_data) &&
        data.Total_posts_data.length > 0)
    );
  };
  const [selectedGraph, setSelectedGraph] = useState(
    "total posts vs total shares"
  );

  const handleGraph = (string) => {
    setSelectedGraph(string);
  };
  const setData = (type) => {
    if (type === "total") {
      setTotalGraphData([]);
    } else if (type === "median") {
      setMedianGraphData([]);
    }

    if (
      Array.isArray(completeDashboardData.total_shares_data) &&
      completeDashboardData.total_shares_data.length > 0
    ) {
      completeDashboardData.total_shares_data
        .slice((currentPage - 1) * limit, currentPage * limit)
        .map((val) => {
          let obj = { page: val.page__page_name };
          if (type === "total") {
            obj["total_likes"] = val.total_likes;
            obj["total_likesColor"] = "hsl(222, 100%, 54%)";
            obj["total_posts"] = val.total_posts;
            obj["total_postsColor"] = "hsl(179, 86%, 42%)";
            obj["total_shares"] = val.total_shares;
            obj["total_sharesColor"] = "hsl(43, 93%, 54%)";
          }
          if (type === "median") {
            obj["total_likes"] = val.total_likes;
            obj["total_likesColor"] = "hsl(222, 100%, 54%)";
            obj["total_posts"] = val.total_posts;
            obj["total_postsColor"] = "hsl(179, 86%, 42%)";
            obj["median_shares"] = val.median_shares;
            obj["median_sharesColor"] = "hsl(43, 93%, 54%)";
          }
          let newKeys = graphKeys.filter((val) => {
            if (val === "total") {
              return val;
            } else if (val === "median") {
              return val;
            }
          });
          setKeys(newKeys);
          setMedianGraphData((prevData) => [...prevData, obj]);
        });
    }
  };
  const getReports = async (sgId, start_date, end_date) => {
    if (modalData.reportType === "dashboard") {
      const { data, error } = await getGeneratedReportList(
        modalData.pk,
        sgId,
        modalData.reportType,
        "",
        "",
        "preview"
      );
      if (error) {
        showAlert("Data not available for the given Date range", "error");
        handleClose();
        return;
      }
      setCompleteDashboardData((prevData) => {
        if (Array.isArray(prevData)) {
          return [...prevData, data];
        } else {
          return [prevData, data];
        }
      });

      const { data: totalPostData, error: totalPostError } =
        await getTotalPostData(modalData.pk, sgId);

      if (totalPostError) {
        showAlert("Data not available for the given Date range", "error");
        handleClose();
        return;
      }
      setTotalPostData((prevData) => ({
        ...prevData,
        [sgId]: [totalPostData.Total_posts_data],
      }));
      setIsLoading(false);
    } else {
      const { data, error } = await getCompleteReport(modalData.pk);
      if (error) {
        showAlert("Data not available for the given Date range", "error");
        handleClose();
        return;
      }
      Object.keys(data).map((val) => {
        if (data[`${val}`]?.top_posts) {
          data[`${val}`]?.top_posts.sort((firstPostValue, secondPostValue) => {
            return secondPostValue.shares - firstPostValue.shares;
          });
        }
        const dataObject = data[`${val}`];
        setCompleteData((prevData) => [...prevData, dataObject]);
      });
    }
  };
  const fetchCurrentTabCsv = async () => {
    try {
      const { data, error } = await getGeneratedReportList(
        modalData.pk,
        "",
        modalData.reportType,
        "",
        "",
        "csv"
      );
      setCsvFile(data);
      if (error) throw error;
      return data;
    } catch (error) {
      console.error(`Error fetching data for sgId `, error);
      return null;
    }
  };

  const getDayName = (dateStr, type) => {
    var date = new Date(dateStr);
    if (type === "range") {
      return date.toLocaleDateString(undefined, options_range);
    }
    return date.toLocaleDateString(undefined, options);
  };
  const dateDiffInDays = (startDate, endDate) => {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(
      date1.getFullYear(),
      date1.getMonth(),
      date1.getDate()
    );
    const utc2 = Date.UTC(
      date2.getFullYear(),
      date2.getMonth(),
      date2.getDate()
    );
    return Math.floor((utc2 - utc1) / MS_PER_DAY);
  };
  const [keys, setKeys] = useState(graphKeys);

  useEffect(() => {
    if (modalData) {
      setCompleteData([]);
      if (modalData.reportType === "dashboard") {
        setIsLoading(true);
        modalData.sgIds.map((sgId) =>
          getReports(sgId, modalData.start_date, modalData.end_date)
        );
        fetchCurrentTabCsv();
      } else {
        getReports();
      }
    } else {
      setCompleteData([]);
    }
  }, [modalData]);
  const customColor = ["#165DFF", "#0FC6C2", "#F7BA1E"];

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    if (
      completeDashboardData.median_shares_data &&
      typeof completeDashboardData.median_shares_data !== "string" &&
      completeDashboardData.median_shares_data.length
    ) {
      setMedianGraphData([]);
      setMedianTotalData(completeDashboardData.median_shares_data.length);
      setMedianPageCount(
        Math.ceil(completeDashboardData.median_shares_data.length / limit)
      );
      setData("median");
    } else {
      setMedianGraphData([]);
    }
  }, [currentMedianPage]);
  useEffect(() => {
    if (
      completeDashboardData.total_shares_data &&
      typeof completeDashboardData.total_shares_data !== "string" &&
      completeDashboardData.total_shares_data.length
    ) {
      setTotalGraphData([]);
      setTotalData(completeDashboardData.total_shares_data.length);
      setPageCount(
        Math.ceil(completeDashboardData.total_shares_data.length / limit)
      );
      setData("total");
    } else {
      setTotalGraphData([]);
    }
  }, [completeDashboardData, currentPage]);
  const previousPageTotalPosts = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPageTotalPosts = () => {
    if (currentPage < totalData) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPageMedianShares = () => {
    if (currentMedianPage > 1) {
      setCurrentMedianPage(currentMedianPage - 1);
    }
  };

  const nextPageMedianShares = () => {
    if (currentMedianPage < mediaTotalData) {
      setCurrentMedianPage(currentMedianPage + 1);
    }
  };
  const options = [
    {
      value: "tabular",
      label: "Tabular View",
      icon: <TabularIcon />,
      selectedIcon: <SelectedTabularIcon />,
    },
    {
      value: "graphical",
      label: "Graphical View",
      icon: <GraphicalIcon />,
      selectedIcon: <SelectedGraphicalIcon />,
    },
  ];
  const reportType =
    modalData.frequency === "d"
      ? "Daily Analysis"
      : modalData.frequency === "w"
      ? "Weekly Analysis"
      : modalData.frequency === "m"
      ? "Monthly Analysis"
      : "";
  return (
    <Container>
      <div className="button_container">
        {completeData.length > 0 && modalData.reportType !== "dashboard" ? (
          <PDFDownloadLink
            document={
              <GeneratePdf
                completeData={completeData}
                startDate={modalData.start_date}
                endDate={modalData.end_date}
                fileName={modalData?.fileName}
                platform={modalData?.platform}
                reportType={modalData?.reportType}
                reportId={modalData?.reportId}
              />
            }
            fileName={
              modalData?.reportType === 9 || modalData?.reportType === 10
                ? `${modalData?.fileName} - ${
                    modalData.start_date === modalData.end_date
                      ? "Daily Report_"
                      : "Weekly Report_"
                  }${modalData.start_date}.pdf`
                : `${modalData?.fileName}.pdf`
            }
          >
            {({ loading }) =>
              loading ? (
                <CustomButton
                  className="button_style"
                  variant="outlined"
                  // disabled={user.user_type.toLowerCase() === "general" && user.sub_access_level !== "L9" ? true : false}
                >
                  Loading Report
                </CustomButton>
              ) : (
                <CustomButton
                  className="button_style"
                  variant="outlined"
                  // disabled={user.user_type.toLowerCase() === "general" && user.sub_access_level !== "L9" ? true : false}
                >
                  Download Report
                </CustomButton>
              )
            }
          </PDFDownloadLink>
        ) : modalData.reportType === "dashboard" &&
          completeDashboardData.length > 0 ? (
          <ExportDropdown
            tabValue={modalData?.platform}
            selectedColumns={
              dateDiffInDays(modalData.start_date, modalData.end_date) === 0 ||
              modalData?.frequency === "d"
                ? modalData?.platform === 1
                  ? columnHeaderFacebookDaily
                  : columnHeaderYoutubeDaily
                : (dateDiffInDays(modalData.start_date, modalData.end_date) >
                    0 &&
                    dateDiffInDays(modalData.start_date, modalData.end_date) <
                      8) ||
                  modalData?.frequency === "w"
                ? modalData?.platform === 1
                  ? columnHeaderFacebookWeekly
                  : columnHeaderYoutubeWeekly
                : modalData?.platform === 1
                ? columnHeaderFacebookMonthly
                : columnHeaderYoutubeMonthly
            }
            pdfData={completeDashboardData}
            // isGenerating={isGenerating}
            validDate={true}
            inProgress={false}
            chipLoading={false}
            csvFile={csvFile}
            type={"preview"}
            selectedFrequency={modalData?.frequency}
          />
        ) : (
          //   <PDFDownloadLink
          //   document={
          //     <PdfSmarDashboard
          //       platform={modalData?.platform}
          //       selectedColumns={dateDiffInDays(modalData.start_date, modalData.end_date)===0||modalData?.frequency==='d'?modalData?.platform===1?columnHeaderFacebookDaily:columnHeaderYoutubeDaily:(dateDiffInDays(modalData.start_date, modalData.end_date)>0 && dateDiffInDays(modalData.start_date, modalData.end_date)<8)||modalData?.frequency==='w'?modalData?.platform===1?columnHeaderFacebookWeekly:columnHeaderYoutubeWeekly:modalData?.platform===1?columnHeaderFacebookMonthly:columnHeaderYoutubeMonthly}
          //       data={completeDashboardData}
          //       selectedFrequency={
          //         modalData?.frequency
          //       }
          //       type={"preview"}
          //     />
          //   }
          //   fileName="smar.pdf"
          //   // onClick={handleDownload}
          // >
          //   {({ blob, url, loading, error }) => {
          //     if (error) {
          //       console.error("Error generating PDF:", error);
          //       return "Error generating PDF";
          //     }
          //     return loading  ? (
          //       <CircularProgress />
          //     ) : (
          //       <CustomButton
          //         // startIcon={<Upload />}
          //         className="button_style"
          //         variant="contained"
          //         // disabled={
          //         //   validDate === false ||
          //         //   inProgress === true ||
          //         //   chipLoading === true ||
          //         //   (tabValue === "youtube"
          //         //     ? selectedPageYouTube.length === 0 &&
          //         //       selectedSmarGroupYouTube.length === 0
          //         //     : selectedSmarGroupFacebook.length === 0 &&
          //         //       selectedPageFacebook.length === 0)
          //         // }
          //       >
          //         Export PDF
          //       </CustomButton>
          //     );
          //   }}
          // </PDFDownloadLink>
          <></>
        )}
      </div>
      <div className="report_container">
        <div id="pdf" ref={myRef}>
          {completeData.length > 0 ? (
            <div className="group_heading_container">
              <Typography className="heading">
                {modalData?.fileName ? modalData?.fileName : ""}{" "}
                {dateDiffInDays(modalData.start_date, modalData.end_date) === 0
                  ? "- Daily Report"
                  : dateDiffInDays(modalData.start_date, modalData.end_date) ===
                    6
                  ? "- Weekly Report"
                  : "- Monthly Report"}
              </Typography>
              <Typography className="heading">
                {modalData.start_date !== modalData.end_date
                  ? `${getDayName(
                      modalData.start_date,
                      "range"
                    )} - ${getDayName(modalData.end_date, "range")}`
                  : getDayName(modalData.start_date, "daily")}
              </Typography>
            </div>
          ) : (
            <></>
          )}
          {completeData.length > 0 ? (
            completeData.map((val, indx) => {
              return (
                <Fragment key={indx}>
                  {val?.top_pages &&
                  (modalData?.platform === 1 ||
                    modalData?.platform === "facebook" ||
                    modalData?.platform === "instagram") ? (
                    <SimpleTable
                      rows={val.top_pages}
                      start_date={modalData.start_date}
                      end_date={modalData.end_date}
                      partyRequired={"required"}
                      reportType={dateDiffInDays(
                        modalData.start_date,
                        modalData.end_date
                      )}
                      platform={modalData?.platform}
                      isDashboard={
                        modalData?.reportType === "dashboard" ? true : false
                      }
                    />
                  ) : val?.top_pages && modalData?.platform === "twitter" ? (
                    <SimpleTableTwitter
                      rows={val.top_pages}
                      start_date={modalData.start_date}
                      end_date={modalData.end_date}
                      partyRequired={"required"}
                      reportType={dateDiffInDays(
                        modalData.start_date,
                        modalData.end_date
                      )}
                    />
                  ) : val?.top_pages && modalData?.platform === "youtube" ? (
                    <SimpleTableYoutube
                      rows={val.top_pages}
                      start_date={modalData.start_date}
                      end_date={modalData.end_date}
                      partyRequired={"required"}
                      reportType={dateDiffInDays(
                        modalData.start_date,
                        modalData.end_date
                      )}
                      isDashboard={
                        modalData?.reportType === "dashboard" ? true : false
                      }
                    />
                  ) : (
                    <></>
                  )}

                  {val?.top_posts &&
                  modalData.start_date === modalData.end_date ? (
                    <div className="img_card_container">
                      {val.top_posts.map((imgVal) => {
                        if (imgVal?.resource?.startsWith("https://")) {
                          return (
                            <PdfImageCard
                              key={imgVal.post_id}
                              image={imgVal.resource}
                              shares={imgVal.shares}
                              name={imgVal.page_name}
                              type="image"
                            />
                          );
                        } else if (imgVal?.resource !== "") {
                          return (
                            <PdfImageCard
                              key={imgVal.post_id}
                              image={imgVal.resource}
                              shares={imgVal.shares}
                              name={imgVal.page_name}
                              type="text"
                            />
                          );
                        }
                      })}
                    </div>
                  ) : (
                    <></>
                  )}

                  {(val[`Bharatiya Janata Party`] || val[`BJP`]) &&
                  (modalData?.platform === "facebook" ||
                    modalData?.platform === "instagram") ? (
                    <SimpleTable
                      rows={val[`Bharatiya Janata Party`] || val[`BJP`]}
                      start_date={modalData.start_date}
                      end_date={modalData.end_date}
                      partyRequired={""}
                      heading={"BJP"}
                      reportType={dateDiffInDays(
                        modalData.start_date,
                        modalData.end_date
                      )}
                      platform={modalData?.platform}
                      isDashboard={
                        modalData?.reportType === "dashboard" ? true : false
                      }
                    />
                  ) : (val[`Bharatiya Janata Party`] || val[`BJP`]) &&
                    modalData?.platform === "youtube" ? (
                    <SimpleTableYoutube
                      rows={val[`Bharatiya Janata Party`] || val[`BJP`]}
                      start_date={modalData.start_date}
                      end_date={modalData.end_date}
                      partyRequired={""}
                      heading={"BJP Accounts"}
                      reportType={dateDiffInDays(
                        modalData.start_date,
                        modalData.end_date
                      )}
                      isDashboard={
                        modalData?.reportType === "dashboard" ? true : false
                      }
                    />
                  ) : (val[`Bharatiya Janata Party`] || val[`BJP`]) &&
                    modalData?.platform === "twitter" ? (
                    <SimpleTableTwitter
                      rows={val[`Bharatiya Janata Party`] || val[`BJP`]}
                      start_date={modalData.start_date}
                      end_date={modalData.end_date}
                      partyRequired={""}
                      heading={"BJP Accounts"}
                      reportType={dateDiffInDays(
                        modalData.start_date,
                        modalData.end_date
                      )}
                    />
                  ) : (
                    <></>
                  )}

                  {(val[`Indian National Congress`] || val[`INC`]) &&
                  (modalData?.platform === "facebook" ||
                    modalData?.platform === "instagram") ? (
                    <SimpleTable
                      rows={val[`Indian National Congress`] || val[`INC`]}
                      start_date={modalData.start_date}
                      end_date={modalData.end_date}
                      partyRequired={""}
                      heading={"INC"}
                      reportType={dateDiffInDays(
                        modalData.start_date,
                        modalData.end_date
                      )}
                      platform={modalData?.platform}
                      isDashboard={
                        modalData?.reportType === "dashboard" ? true : false
                      }
                    />
                  ) : (val[`Indian National Congress`] || val[`INC`]) &&
                    modalData?.platform === "youtube" ? (
                    <SimpleTableYoutube
                      rows={val[`Indian National Congress`] || val[`INC`]}
                      start_date={modalData.start_date}
                      end_date={modalData.end_date}
                      partyRequired={""}
                      heading={"INC Accounts"}
                      reportType={dateDiffInDays(
                        modalData.start_date,
                        modalData.end_date
                      )}
                      isDashboard={
                        modalData?.reportType === "dashboard" ? true : false
                      }
                    />
                  ) : (val[`Indian National Congress`] || val[`INC`]) &&
                    modalData?.platform === "twitter" ? (
                    <SimpleTableTwitter
                      rows={val[`Indian National Congress`] || val[`INC`]}
                      start_date={modalData.start_date}
                      end_date={modalData.end_date}
                      partyRequired={""}
                      heading={"INC Accounts"}
                      reportType={dateDiffInDays(
                        modalData.start_date,
                        modalData.end_date
                      )}
                    />
                  ) : (
                    <></>
                  )}

                  {(val[`Aam Aadmi Party`] || val[`AAP`]) &&
                  (modalData?.platform === "facebook" ||
                    modalData?.platform === "instagram") ? (
                    <SimpleTable
                      rows={val[`Aam Aadmi Party`] || val[`AAP`]}
                      start_date={modalData.start_date}
                      end_date={modalData.end_date}
                      partyRequired={""}
                      heading={"AAP"}
                      reportType={dateDiffInDays(
                        modalData.start_date,
                        modalData.end_date
                      )}
                      platform={modalData?.platform}
                      isDashboard={
                        modalData?.reportType === "dashboard" ? true : false
                      }
                    />
                  ) : (val[`Aam Aadmi Party`] || val[`AAP`]) &&
                    modalData?.platform === "youtube" ? (
                    <SimpleTableYoutube
                      rows={val[`Aam Aadmi Party`] || val[`AAP`]}
                      start_date={modalData.start_date}
                      end_date={modalData.end_date}
                      partyRequired={""}
                      heading={"AAP Accounts"}
                      reportType={dateDiffInDays(
                        modalData.start_date,
                        modalData.end_date
                      )}
                      isDashboard={
                        modalData?.reportType === "dashboard" ? true : false
                      }
                    />
                  ) : (val[`Aam Aadmi Party`] || val[`AAP`]) &&
                    modalData?.platform === "twitter" ? (
                    <SimpleTableTwitter
                      rows={val[`Aam Aadmi Party`] || val[`AAP`]}
                      start_date={modalData.start_date}
                      end_date={modalData.end_date}
                      partyRequired={""}
                      heading={"AAP Accounts"}
                      reportType={dateDiffInDays(
                        modalData.start_date,
                        modalData.end_date
                      )}
                    />
                  ) : (
                    <></>
                  )}

                  {val &&
                  (modalData?.platform === "facebook" ||
                    modalData?.platform === "instagram") ? (
                    Object.keys(val).map((key) => {
                      return (
                        <Fragment>
                          {key !== "top_posts" &&
                          key !== "top_pages" &&
                          key !== "Bharatiya Janata Party" &&
                          key !== "BJP" &&
                          key !== "INC" &&
                          key !== "AAP" &&
                          key !== "Indian National Congress" &&
                          key !== "Aam Aadmi Party" ? (
                            <SimpleTable
                              rows={val[`${key}`]}
                              start_date={modalData.start_date}
                              end_date={modalData.end_date}
                              partyRequired={""}
                              heading={key}
                              reportType={dateDiffInDays(
                                modalData.start_date,
                                modalData.end_date
                              )}
                              platform={modalData?.platform}
                              isDashboard={
                                modalData?.reportType === "dashboard"
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </Fragment>
                      );
                    })
                  ) : val && modalData?.platform === "youtube" ? (
                    Object.keys(val).map((key) => {
                      return (
                        <Fragment>
                          {key !== "top_posts" &&
                          key !== "top_pages" &&
                          key !== "Bharatiya Janata Party" &&
                          key !== "BJP" &&
                          key !== "INC" &&
                          key !== "AAP" &&
                          key !== "Indian National Congress" &&
                          key !== "Aam Aadmi Party" ? (
                            <SimpleTableYoutube
                              rows={val[`${key}`]}
                              start_date={modalData.start_date}
                              end_date={modalData.end_date}
                              partyRequired={""}
                              heading={key}
                              reportType={dateDiffInDays(
                                modalData.start_date,
                                modalData.end_date
                              )}
                              isDashboard={
                                modalData?.reportType === "dashboard"
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </Fragment>
                      );
                    })
                  ) : val && modalData?.platform === "twitter" ? (
                    Object.keys(val).map((key) => {
                      return (
                        <Fragment>
                          {key !== "top_posts" &&
                          key !== "top_pages" &&
                          key !== "Bharatiya Janata Party" &&
                          key !== "BJP" &&
                          key !== "INC" &&
                          key !== "AAP" &&
                          key !== "Indian National Congress" &&
                          key !== "Aam Aadmi Party" ? (
                            <SimpleTableTwitter
                              rows={val[`${key}`]}
                              start_date={modalData.start_date}
                              end_date={modalData.end_date}
                              partyRequired={""}
                              heading={key}
                              reportType={dateDiffInDays(
                                modalData.start_date,
                                modalData.end_date
                              )}
                            />
                          ) : (
                            <></>
                          )}
                        </Fragment>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </Fragment>
              );
            })
          ) : (
            <></>
          )}
          {modalData.reportType === "dashboard" &&
            completeDashboardData.length > 0 && (
              <Box>
                <Typography
                  fontWeight={"bold"}
                  className="heading"
                  sx={{ mb: 2 }}
                >
                  {reportType}
                </Typography>
              </Box>
            )}
          {modalData.reportType === "dashboard" &&
            completeDashboardData.length > 0 &&
            completeDashboardData.map((smargroup, smargroupIndex) => {
              return smargroup.data.map((val, index) => {
                const isTabular =
                  selectedToggles[
                    `${val?.start_date}-${index}-${val?.smar_group_id}`
                  ] === "tabular" ||
                  !selectedToggles[
                    `${val?.start_date}-${index}-${val?.smar_group_id}`
                  ];
                const isYoutube = modalData?.platform === 3;
                return (
                  <Fragment
                    key={`${val?.start_date}-${index}-${val?.smar_group_id}`}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      className="group_heading_container"
                    >
                      <Typography fontWeight={"bold"} className="heading">
                        {val.smar_group_name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: 1,
                          alignItems: "center",
                        }}
                      >
                        <Typography className="heading">
                          {val.start_date !== val.end_date
                            ? `${getDayName(
                                val.start_date,
                                "range"
                              )} - ${getDayName(val.end_date, "range")}`
                            : getDayName(val.start_date, "daily")}
                        </Typography>
                        <ToggleGroup
                          options={options}
                          onChange={(selectedValue) =>
                            handleChange(
                              selectedValue,
                              `${val?.start_date}-${index}-${val?.smar_group_id}`
                            )
                          }
                          value={
                            selectedToggles[
                              `${val?.start_date}-${index}-${val?.smar_group_id}`
                            ] || "tabular"
                          }
                        />
                      </Box>
                    </Box>
                    {isTabular ? (
                      isYoutube ? (
                        <SimpleTableYoutube
                          rows={val.smargroup_data}
                          start_date={val.start_date}
                          end_date={val.end_date}
                          partyRequired={"required"}
                          reportType={dateDiffInDays(
                            val.start_date,
                            val.end_date
                          )}
                          isDashboard={
                            modalData?.reportType === "dashboard" ? true : false
                          }
                        />
                      ) : (
                        <SimpleTable
                          rows={val.smargroup_data}
                          start_date={val.start_date}
                          end_date={val.end_date}
                          partyRequired={"required"}
                          reportType={dateDiffInDays(
                            val.start_date,
                            val.end_date
                          )}
                          platform={"facebook"}
                          isDashboard={
                            modalData?.reportType === "dashboard" ? true : false
                          }
                        />
                      )
                    ) : (
                      <></>
                    )}
                    {/* {selectedToggles[val?.smar_group_id] || "tabular" ===
                      "tabular" && modalData.platform === 3 ? (
                      <SimpleTableYoutube
                        rows={val.smargroup_data}
                        start_date={val.start_date}
                        end_date={val.end_date}
                        partyRequired={"required"}
                        reportType={dateDiffInDays(
                          val.start_date,
                          val.end_date
                        )}
                      />
                    ) : selectedToggles[val?.smar_group_id] || "tabular" ===
                      "tabular" &&  (
                      <SimpleTable
                        rows={val.smargroup_data}
                        start_date={val.start_date}
                        end_date={val.end_date}
                        partyRequired={"required"}
                        reportType={dateDiffInDays(
                          val.start_date,
                          val.end_date
                        )}
                      />
                    )} */}
                    {(selectedToggles[
                      `${val?.start_date}-${index}-${val?.smar_group_id}`
                    ] || "tabular") === "tabular" &&
                      val.top_post.length > 0 && (
                        <TopPosts
                          posts={val.top_post}
                          tabValue={
                            modalData.platform === 1 ? "facebook" : "youtube"
                          }
                        />
                      )}
                    {(selectedToggles[
                      `${val?.start_date}-${index}-${val?.smar_group_id}`
                    ] || "tabular") === "graphical" && (
                      <Box className="chip_data_container">
                        <Box sx={{ mb: 2 }}>
                          <FormControl fullWidth>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1,
                                "& > :not(style)": {
                                  m: 1,
                                },
                              }}
                            >
                              <Chip
                                label={
                                  modalData.platform == 3
                                    ? "Total Videos vs Total Views"
                                    : "Total Posts vs Total Shares"
                                }
                                sx={{
                                  backgroundColor:
                                    selectedGraph ===
                                    "total posts vs total shares"
                                      ? "#1B90FE"
                                      : "rgba(27, 144, 254, 0.12)",
                                  color:
                                    selectedGraph ===
                                    "total posts vs total shares"
                                      ? "white"
                                      : "black",
                                  "&:hover": {
                                    backgroundColor:
                                      selectedGraph ===
                                      "total posts vs total shares"
                                        ? "#1B90FE"
                                        : "rgba(27, 144, 254, 0.2)",
                                  },
                                }}
                                onClick={() =>
                                  handleGraph("total posts vs total shares")
                                }
                              />
                              <Chip
                                label={
                                  modalData.platform == 3
                                    ? "Median Views vs Max Views"
                                    : "Median Shares vs Max Shares"
                                }
                                sx={{
                                  backgroundColor:
                                    selectedGraph ===
                                    "median shares vs max shares"
                                      ? "#1B90FE"
                                      : "rgba(27, 144, 254, 0.12)",
                                  color:
                                    selectedGraph ===
                                    "median shares vs max shares"
                                      ? "white"
                                      : "black",
                                  "&:hover": {
                                    backgroundColor:
                                      selectedGraph ===
                                      "median shares vs max shares"
                                        ? "#1B90FE"
                                        : "rgba(27, 144, 254, 0.2)",
                                  },
                                }}
                                onClick={() =>
                                  handleGraph("median shares vs max shares")
                                }
                              />
                              <Chip
                                label={
                                  modalData.platform == 3
                                    ? "Total Videos vs Median Views"
                                    : "Total Posts vs Median Shares"
                                }
                                sx={{
                                  backgroundColor:
                                    selectedGraph ===
                                    "total posts vs median shares"
                                      ? "#1B90FE"
                                      : "rgba(27, 144, 254, 0.12)",
                                  color:
                                    selectedGraph ===
                                    "total posts vs median shares"
                                      ? "white"
                                      : "black",
                                  "&:hover": {
                                    backgroundColor:
                                      selectedGraph ===
                                      "total posts vs median shares"
                                        ? "#1B90FE"
                                        : "rgba(27, 144, 254, 0.2)",
                                  },
                                }}
                                onClick={() =>
                                  handleGraph("total posts vs median shares")
                                }
                              />
                            </Box>
                          </FormControl>
                        </Box>
                        <GraphSection
                          graphData={val?.graph_data}
                          graphType="median"
                          tabValue={
                            modalData.platform === 3 ? "youtube" : "facebook"
                          }
                          selectedGraph={selectedGraph}
                        />
                      </Box>
                    )}
                    {(selectedToggles[
                      `${val?.start_date}-${index}-${val?.smar_group_id}`
                    ] || "tabular") === "graphical" &&
                      `${val?.start_date !== val?.end_date}` &&
                      modalData.frequency !== "d" && (
                        <Box className="data_container">
                          <GraphSection
                            graphData={val?.graph_data}
                            graphType="median"
                            tabValue={
                              modalData.platform === 3 ? "youtube" : "facebook"
                            }
                            selectedGraph={"number of days won"}
                          />
                        </Box>
                      )}
                    {(selectedToggles[
                      `${val?.start_date}-${index}-${val?.smar_group_id}`
                    ] || "tabular") === "graphical" &&
                      hasTotalPostData(
                        totalPostData[val?.smar_group_id][0]
                      ) && (
                        <Box className="data_container">
                          <LineChartComponent
                            data={totalPostData[val?.smar_group_id][0]}
                            tabValue={
                              modalData.platform === 1 ? "facebook" : "youtube"
                            }
                          />
                        </Box>
                      )}
                  </Fragment>
                );
              });
            })}
          {loading && <Loader />}
        </div>
      </div>
    </Container>
  );
};

export default ShowReportsPreview;
