import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import KarmaRegular from "assets/fonts/Karma-Regular.ttf";
import KarmaSemiBold from "assets/fonts/Karma-SemiBold.ttf";
import KarmaBold from "assets/fonts/Karma-Bold.ttf";
import NotoSansRegular from "assets/fonts/arial-unicode-ms.ttf";
import NotoSansBold from "assets/fonts/arial-unicode-ms-bold.ttf";
import { columnHeaderFacebookDaily } from "utils/data";

Font.register({
  family: "Roboto",
  fonts: [
    { src: NotoSansRegular, fontWeight: 400 },
    { src: NotoSansBold, fontWeight: 600 },
  ],
});

const styles = StyleSheet.create({
  page: { padding: 30 },
  section: { marginBottom: 10 },
  row: {
    flexDirection: "row",
    borderBottom: "1px solid #e0e0e0",
  },
  cell: {
    padding: 4,
    fontSize: 9,
    textAlign: "center",
  },
  headerCell: {
    fontWeight: "bold",
    backgroundColor: "#084B83",
    color: "#FFFFFF",
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
    fontWeight: "bold",
    textAlign: "center",
  },
  subtitle: {
    fontSize: 12,
    marginBottom: 5,
  },
  centerContent: {
    alignItems: "center",
  },
});

const PdfSmarDashboard = ({
  platform,
  selectedColumns,
  data,
  selectedFrequency,
  type,
}) => {
  // Random color logic commented out
  /*
  const randomColour = (value, maxValue) => {
    if (value === 0 || maxValue === 0) return "#FFFFFF";
    const colors = ["#70db70", "#85e085", "#99e699", "#adebad", "#c2f0c2", "#d6f5d6", "#ebfaeb"];
    const index = Math.floor((value / maxValue) * (colors.length - 1));
    return colors[index];
  };
  */
  const renderHeader = (id) => {
    return (
      <View style={styles.row}>
        {(type !== "preview" ? selectedColumns[id] : selectedColumns).map(
          (column, index) => {
            let columnWidth;
            const narrowColumns = [
              "page__page_name",
              "page__political_party__name",
            ];
            const narrowColumnCount = (
              type !== "preview" ? selectedColumns[id] : selectedColumns
            ).filter((col) => narrowColumns.includes(col.id)).length;
            const narrowColumnWidth = "15%";
            const remainingWidth =
              100 - narrowColumnCount * parseFloat(narrowColumnWidth);
            const wideColumnCount =
              (type !== "preview" ? selectedColumns[id] : selectedColumns)
                .length - narrowColumnCount;

            if (narrowColumns.includes(column.id)) {
              columnWidth = narrowColumnWidth;
            } else {
              columnWidth = `${remainingWidth / wideColumnCount}%`;
            }

            return (
              <View
                key={column.id}
                style={[
                  styles.cell,
                  styles.headerCell,
                  { width: columnWidth, borderRight: "1px solid #e0e0e0" },
                  index === 0 && { borderLeft: "1px solid #e0e0e0" },
                ]}
              >
                <Text>{column.label}</Text>
              </View>
            );
          }
        )}
      </View>
    );
  };

  const renderRow = (item, rowIndex, id) => (
    <View style={styles.row} key={rowIndex} wrap={false}>
      {(type !== "preview" ? selectedColumns[id] : selectedColumns).map(
        (column, cellIndex) => {
          const narrowColumns = [
            "page__page_name",
            "page__political_party__name",
          ];
          const narrowColumnCount = (
            type !== "preview" ? selectedColumns[id] : selectedColumns
          ).filter((col) => narrowColumns.includes(col.id)).length;
          const narrowColumnWidth = "15%";
          const remainingWidth =
            100 - narrowColumnCount * parseFloat(narrowColumnWidth);
          const wideColumnCount =
            (type !== "preview" ? selectedColumns[id] : selectedColumns)
              .length - narrowColumnCount;

          let columnWidth;
          if (narrowColumns.includes(column.id)) {
            columnWidth = narrowColumnWidth;
          } else {
            columnWidth = `${remainingWidth / wideColumnCount}%`;
          }

          const cellStyle = [
            styles.cell,
            { width: columnWidth, borderRight: "1px solid #e0e0e0 !important" },
            cellIndex === 0 && { borderLeft: "1px solid #e0e0e0" },
          ];

          // Random color logic commented out
          /*
        if (['score', 'total_posts', 'total_shares', 'num_posts_in_top', 'max_shares', 'median_shares', 'no_of_days_won'].includes(column.id)) {
          const maxValue = Math.max(...data.flatMap(d => d.data.flatMap(g => g.smargroup_data.map(i => i[column.id]))));
          cellStyle.push({ backgroundColor: randomColour(item[column.id], maxValue) });
        }
        */

          return (
            <View key={column.id} style={cellStyle}>
              {column.id === "verification_status" ? (
                item[column.id] === true ? (
                  <Text style={{ fontFamily: "Roboto" }}>{"True"}</Text>
                ) : (
                  <Text style={{ fontFamily: "Roboto" }}>{"False"}</Text>
                )
              ) :column.id === "followers_count" ? (
                item[column.id] === null ? (
                  <Text style={{ fontFamily: "Roboto" }}>{0}</Text>
                ) : (
                  <Text style={{ fontFamily: "Roboto" }}>{item[column.id]}</Text>
                )
              ) : (
                <Text style={{ fontFamily: "Roboto" }}>{item[column.id]}</Text>
              )}
            </View>
          );
        }
      )}
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>{`${
          selectedFrequency === "d"
            ? "Daily Analysis"
            : selectedFrequency === "w"
            ? "Weekly Analysis"
            : selectedFrequency === "m"
            ? "Monthly Analysis"
            : ""
        }`}</Text>
        {data.map((eachSmarData, index) => (
          <View key={index} break={index !== 0}>
            {eachSmarData.data.map((tableData, idx) => (
              <View key={idx} style={styles.centerContent}>
                <Text
                  style={{
                    fontSize: "16px",
                    marginVertical: "10px",
                    textAlign: "center",
                  }}
                >
                  {tableData.smar_group_name}
                </Text>
                <View style={styles.subtitle}>
                  {tableData.start_date !== tableData.end_date &&
                  selectedFrequency !== "d" ? (
                    <Text
                      style={{ fontSize: "12px" }}
                    >{`(${tableData.start_date} - ${tableData.end_date})`}</Text>
                  ) : (
                    <Text
                      style={{ fontSize: "12px" }}
                    >{`(${tableData.start_date})`}</Text>
                  )}
                </View>
                {renderHeader(
                  tableData?.smargroup_data[0]?.smar_group_id &&
                    tableData?.smargroup_data[0].smar_group_id
                )}
                {tableData.smargroup_data.map((item, index) =>
                  renderRow(
                    item,
                    index,
                    tableData.smargroup_data[0]?.smar_group_id &&
                      tableData.smargroup_data[0].smar_group_id
                  )
                )}
              </View>
            ))}
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default PdfSmarDashboard;
