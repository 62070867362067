import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import CircularProgress from '@mui/material/CircularProgress';
import PdfSmarDashboard from 'components/Pdf/PdfSmarDashboard';
import { Upload } from '@mui/icons-material';

const ExportDropdown = ({ 
  tabValue, 
  selectedColumns, 
  pdfData, 
  handleDownload, 
  isGenerating, 
  validDate, 
  inProgress, 
  chipLoading, 
  selectedPageYouTube, 
  selectedSmarGroupYouTube, 
  selectedSmarGroupFacebook, 
  selectedPageFacebook,
  csvFile,
  type,
  selectedFrequency
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCSVExport = () => {
    // Assuming csvFile is a string containing the CSV data
    const csvContent = csvFile;
  
    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  
    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);
  
    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.csv');
    
    // Append the link to the body (required for Firefox)
    document.body.appendChild(link);
  
    // Programmatically click the link to trigger the download
    link.click();
  
    // Clean up by removing the link and revoking the Blob URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  
    handleClose();
  };

  const isExportDisabled = 
    validDate === false ||
    inProgress === true ||
    chipLoading === true 
    // ||
    // (tabValue === "youtube"
    //   ? selectedPageYouTube?.length === 0 && selectedSmarGroupYouTube?.length === 0
    //   : selectedSmarGroupFacebook?.length === 0 && selectedPageFacebook?.length === 0);

  return (
    <>
      <Button
        // endIcon={<ChevronDown/>}
        onClick={handleClick}
        variant="contained"
        disabled={isExportDisabled}
        sx={{textTransform:'capitalize'}}
      >
        Export
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          {pdfData?.length > 0 && (
            <PDFDownloadLink
              document={
                <PdfSmarDashboard
                  platform={tabValue}
                  selectedColumns={selectedColumns}
                  data={pdfData}
                  selectedFrequency={selectedFrequency}
                  type={type}
                />
              }
              fileName="smar.pdf"
              onClick={(event) => {
                event.stopPropagation();
                handleDownload();
                handleClose();
              }}
            >
              {({ blob, url, loading, error }) => {
                if (error) {
                  console.error("Error generating PDF:", error);
                  return "Error generating PDF";
                }
                return loading || isGenerating ? (
                  <CircularProgress size={24} />
                ) : (
                  <Button
                    startIcon={<Upload />}
                    disabled={isExportDisabled}
                    sx={{textTransform:'capitalize'}}
                  >
                    Export PDF
                  </Button>
                );
              }}
            </PDFDownloadLink>
          )}
        </MenuItem>
        <MenuItem onClick={handleCSVExport}>
          <Button
            startIcon={<Upload />}
            disabled={isExportDisabled}
            sx={{textTransform:'capitalize'}}
          >
            Export CSV
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ExportDropdown;